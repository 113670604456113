<template>
  <div>

    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="编号">
              <a-input v-model="queryParam.number" placeholder="请输入组编号" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="企业名称">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-model="queryParam.corporation_id"
                v-decorator="[
                  'corporation_id',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]"
                placeholder="请输入企业名称"
                :default-active-first-option="false"
                @search="corporationSearch"
                @change="corporationChange"
              >
                <a-select-option v-for="d in dataSource" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="是否可用">
              <a-select
                placeholder="请选择"
                allowClear
                v-model="queryParam.is_available"
              >
                <a-select-option v-for="(val,key) in isAvailableDir" :key="key" :value="key">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" v-show="record.is_available"/>
          <a-popconfirm
            title="确定要禁用么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
            v-show="record.is_available"
          >
            <a>禁用</a>
          </a-popconfirm>
          <a-divider type="vertical"/>
          <a @click="handleWatch(record)">查看</a>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <edit-form
      ref="editModal"
      :visible="editvisible"
      :loading="editconfirmLoading"
      :model="editmdl"
      @cancel="handleEditCancel"
      @ok="handleEditOk"
      :key="key"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  electricity_meter_list,
  electricity_meter_create,
  electricity_meter_delete,
  electricity_meter_update
} from '@/api/electricity-meter'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import EditForm from '../modules/EditForm'
import { corporation_list } from '@/api/corporation'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      key: 1,
      pageNum: 1,
      loading: false,
      confirmLoading: false,
      editconfirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      editvisible: false,
      dataSource: [],
      mdl: {},
      editmdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      isAvailableDir: { 'true': '是', 'false': '否' },
      // 查询参数
      queryParam: { is_available: 'true' },
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '编号',
          dataIndex: 'number',
          ellipsis: true
        },
        {
          title: '所在组',
          dataIndex: 'group_name',
          ellipsis: true
        },
        {
          title: '公司',
          ellipsis: true,
          dataIndex: 'corporation_name'
        },
        {
          title: '所有人',
          dataIndex: 'property_owner',
          width: 100,
          align: 'center',
          customRender: (text) => this.$Dictionaries.electricity_meter_property_owner[text]
        },
        {
          title: '预付表',
          dataIndex: 'is_prepay',
          width: 100,
          align: 'center',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '是否可用',
          dataIndex: 'is_available',
          width: 100,
          align: 'center',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '电表初值',
          dataIndex: 'initial_value',
          width: 100
        },
        {
          title: '倍率',
          ellipsis: true,
          dataIndex: 'rate'
        },
        {
          title: '时间',
          width: 200,
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return electricity_meter_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    // 公司
    corporationSearch (value) {
      corporation_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        // this.dataSource = !value ? [] : result
        this.dataSource = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    corporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handlecorporationSearch(value)
      }
    },
    handleEditCancel () {
      this.editvisible = false
      const form = this.$refs.editModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    // isAvailableChange (value) {
    //   console.log(value)
    //   if (value === undefined) {
    //     this.queryParam.is_available = true
    //   }
    // },
    handleWatch (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({
        path: '/electricity_meter/' + record.id,
        query: { number: record.number }
      })
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.key++
      this.editmdl = record
      this.editvisible = true
    },
    handleDelet (record) {
      electricity_meter_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          electricity_meter_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            console.log('删除成功------')
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleEditOk () {
      const form = this.$refs.editModal.form
      this.editconfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          electricity_meter_update(values, this.editmdl.id).then(res => {
              this.editvisible = false
              this.editconfirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            console.log('成功------')
          })
        } else {
          this.editconfirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
